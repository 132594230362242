import { Box, Container, Typography } from "@mui/material";
import React from "react";
import theme from "../Theme";
// import { IMAGE_URL } from "../url";

const Footer = () => {
    return (
        // make a simple header with a logo
        <Box sx={{
            backgroundColor: theme.palette.primary.Lightbg2,
            paddingTop: '64px',
            position: 'relative',
            marginTop: {

                xs: '83px',
                sm: '83px',
                md: '167px',
                lg: '167px',
            },
        }}>
            <Container maxWidth="xl">
                <Box sx={{
                    width: '100%',
                    display: {

                        xs: 'block',
                        sm: 'block',
                        md: 'flex',
                        lg: 'flex',
                    },
                    gap: '20px'
                }}
                >

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>

                        <Box sx={{
                        }} href="#" component={"a"}
                            className="revealator-slideright revealator-duration15 revealator-delay5"
                        >
                            <Box sx={{
                                height: '43px',
                                width: '140px',
                                objectFit: 'contain',
                            }}
                                component={"img"}
                                src={"/assets/images/logo.png"} alt="Logo"

                            />
                        </Box>
                        <Box sx={{
                            width: '400px',
                            margin: '32px 0'
                        }}
                            className="revealator-slideright revealator-duration15 revealator-delay10"
                        >
                            <Typography
                                sx={{
                                    fontSize: '32px',
                                    fontWeight: '400',
                                    letterSpacing: '-0.96px',
                                    color: theme.palette.primary.logoColor,
                                    textAlign: 'center'
                                }}
                            >
                                Own your title as an OG and be in the first 1000 on SOUND³
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme.palette.primary.white,
                                borderRadius: '39px',
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                boxShadow: '0px 0px 0px 4px rgba(242, 244, 247, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                padding: '10px 0',
                                width: '320px'

                            }}
                            component={"a"} href="#"
                            className="revealator-slideright revealator-duration15 revealator-delay15"
                        >
                            <Box
                                component={"img"}
                                src={"/assets/images/Social button.png"} alt="Google"
                                sx={{
                                    width: '48px',
                                }}
                            />

                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    paddingLeft: '12px',
                                    color: theme.palette.primary.logoColor,
                                }}
                            >
                                Join the waiting list
                            </Typography>
                        </Box>
                    </Box>



                    <Box
                        sx={{
                            display: {

                                xs: 'none',
                                sm: 'none',
                                md: 'block',
                                lg: 'block',
                            },

                            width: '100%',
                            margin: '64px 0'
                        }}

                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                            className='footerRightSec'

                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        color: theme.palette.primary.logoColor,
                                        marginBottom: '16px'
                                    }}

                                >
                                    CONTACT
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        lineHeight: '24px',
                                        color: theme.palette.primary.textColor
                                    }}
                                >
                                    Info@sound3.co.uk
                                </Typography>
                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        color: theme.palette.primary.logoColor
                                    }}
                                >
                                    FOLLOW US
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    marginTop: '16px',
                                    position: 'relative',
                                    zIndex: 1
                                }}>
                                    <a href="https://www.youtube.com/channel/UCFeUYbma0HGsBCBUywXgWgA" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component={"img"}
                                            src={"/assets/images/youtubeIcon.png"} alt="Youtube"
                                            sx={{
                                                width: '50px',
                                            }}
                                        />
                                    </a>
                                    <a href="https://www.instagram.com/getsound3?igsh=MWQ4eXUycTN1MnI2ZQ==" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component={"img"}
                                            src={"/assets/images/instaIcon.png"} alt="Instagram"
                                            sx={{
                                                width: '50px',
                                            }}
                                        />
                                    </a>
                                    <a href="https://x.com/wearesound3?s=21&t=tOB-QmUFPj7UD51S4Rl9AQ" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component={"img"}
                                            src={"/assets/images/twitterIcon.png"} alt="X"
                                            sx={{
                                                width: '50px',
                                            }}
                                        />
                                    </a>
                                    <a href="https://www.tiktok.com/@wearesound3?lang=en" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component={"img"}
                                            src={"/assets/images/tiktokIcon.png"} alt="Tiktok"
                                            sx={{
                                                width: '50px',
                                            }}
                                        />
                                    </a>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                marginTop: '92px'
                            }}>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    color: theme.palette.primary.textColor
                                }}
                            >
                                © 2023 — Copyright
                            </Typography>
                        </Box>

                    </Box>



                </Box>

            </Container >

            <Box
                sx={{
                    display: {

                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none',
                    },
                    borderTop: `1px solid ${theme.palette.primary.logoColor}`,
                    borderLeft: `1px solid ${theme.palette.primary.logoColor}`,
                    borderRadius: '128px 0px 0px 0px',
                    width: '100%',
                    margin: '56px 0 0 0'
                }}
                className="revealator-slideup revealator-duration15 revealator-delay5"
            >
                <Box
                    sx={{
                        display: {

                            xs: 'block',
                            sm: 'block',
                            md: 'block',
                            lg: 'none',
                        },
                        marginLeft: '64px',
                        marginTop: '64px',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '600',
                                lineHeight: '24px',
                                color: theme.palette.primary.logoColor,
                                marginBottom: '16px'
                            }}

                        >
                            CONTACT
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: theme.palette.primary.textColor
                            }}
                        >
                            Info@sound3.co.uk
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '600',
                                lineHeight: '24px',
                                color: theme.palette.primary.logoColor
                            }}
                        >
                            FOLLOW US
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: '16px',
                            position: 'relative',
                            zIndex: 1
                        }}>
                            <a href="https://www.youtube.com/channel/UCFeUYbma0HGsBCBUywXgWgA" target="_blank" rel="noopener noreferrer">
                                <Box
                                    component={"img"}
                                    src={"/assets/images/youtubeIcon.png"} alt="Youtube"
                                    sx={{
                                        width: '50px',
                                    }}
                                />
                            </a>
                            <a href="https://www.instagram.com/getsound3?igsh=MWQ4eXUycTN1MnI2ZQ==" target="_blank" rel="noopener noreferrer">
                                <Box
                                    component={"img"}
                                    src={"/assets/images/instaIcon.png"} alt="Instagram"
                                    sx={{
                                        width: '50px',
                                    }}
                                />
                            </a>
                            <a href="https://x.com/wearesound3?s=21&t=tOB-QmUFPj7UD51S4Rl9AQ" target="_blank" rel="noopener noreferrer">
                                <Box
                                    component={"img"}
                                    src={"/assets/images/twitterIcon.png"} alt="X"
                                    sx={{
                                        width: '50px',
                                    }}
                                />
                            </a>
                            <a href="https://www.tiktok.com/@wearesound3?lang=en" target="_blank" rel="noopener noreferrer">
                                <Box
                                    component={"img"}
                                    src={"/assets/images/tiktokIcon.png"} alt="Tiktok"
                                    sx={{
                                        width: '50px',
                                    }}
                                />
                            </a>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        marginTop: '92px',
                        marginLeft: '64px',
                        paddingBottom: '64px'
                    }}>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: theme.palette.primary.textColor
                        }}
                    >
                        © 2023 — Copyright
                    </Typography>
                </Box>

            </Box>

        </Box >


    )
}

export default Footer
