import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { selectCurrentArtist } from "../../redux/reducers/artistSlice";
import { useAppSelector } from "../../redux/hooks";
import { styles } from '../CheckOutForm/style'
import { Box, Button, Typography } from "@mui/material";
import theme from "../../Theme";


export default function CheckoutForm({ amount, clientSecret, route }) {
    const stripe = useStripe();
    const elements = useElements();

    const currentArtist = useAppSelector(selectCurrentArtist);
    useEffect(() => {
        console.log("currentArtist", currentArtist)
        console.log("currentArtistIDs", currentArtist.artist.id, " ", currentArtist.album.album_id)
    }, [])


    console.log("Current Artist in Checkout Form ==> ", route);

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // const clientSecret = new URLSearchParams(window.location.search).get(
        //     "payment_intent_client_secret"
        // );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then((paymentResponse) => {

            console.log("paymentResponse", paymentResponse)
            const { paymentIntent } = paymentResponse

        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log("button clicked");

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);
        localStorage.removeItem("paymentCreate")
        localStorage.removeItem("stripeInitialized")
        const paymentResponse = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `https://sound3.co.uk/admin/api/stripeSuccess?client_reference_id=${currentArtist.artist.id}_${currentArtist.album.album_id}`,
            },
        })

        const { error } = paymentResponse
        // alert(paymentResponse)
        console.log(paymentResponse)



        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsProcessing(false);
    };

    return (
        <Box sx={styles.paymentFormBoxOuter}>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.primary.logoColor,
                    fontWeight: 500,
                    marginBottom: "10px",
                    fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                    },
                    textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'center',
                        lg: 'center',
                    },

                }}
            >
                Amount to be paid: {(amount / 100).toFixed(2)}
            </Typography>
            <Box sx={styles.paymentFormBox}>
                <form className="paymentFormInner" id="payment-form" onSubmit={handleSubmit}>

                    <PaymentElement id="payment-element" />
                    <Button type="submit" sx={styles.submitBtn} disabled={isProcessing || !stripe || !elements} id="submit">
                        <span id="button-text">
                            {isProcessing ? "Processing ... " : "Pay now"}
                        </span>
                    </Button>
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </Box>
        </Box>

    );
}