import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';
import theme from '../../Theme';
import ShareButtonComponent from './Share';

function QRCodeItem() {

    return (
        <Box sx={styles.DropBox}>
            <Box sx={styles.DropLeftArea}>
                <Box sx={styles.DropImg}>
                    <Box
                        component={'img'}
                        src={"/assets/images/QR.png"}
                        alt="Drop Image"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
                <Box sx={styles.DropContent}>
                    <Typography variant="h6" sx={styles.DropBoxTitle}>Bryan Lee</Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        lineHeight: '1',
                        color: theme.palette.primary.textColor,
                    }}>
                        <Box sx={{
                            fontSize: '22px',
                        }}><Icon icon="iconamoon:link-light" /></Box>
                        <Typography component={'p'} sx={styles.DropBoxSubTitle}>Album</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        lineHeight: '1',
                        color: theme.palette.primary.textColor,
                    }}>
                        <Box sx={{
                            fontSize: '22px',
                        }}><Icon icon="ph:arrow-bend-up-right-fill" />
                        </Box>
                        <Typography component={'a'} href='#' sx={styles.DropBoxSubTitle}>http://stage3.com/</Typography>

                    </Box>
                </Box>
            </Box>
            <Box sx={styles.DropRightArea}>
                {/* <Button variant="contained" sx={styles.DropBtn}>
                    <Box sx={styles.DropBtnIcon}>
                        <Icon icon="solar:share-bold" />
                    </Box>
                    <Typography sx={styles.DropBoxBtnText}> Share</Typography>

                </Button> */}
                <ShareButtonComponent/>


            </Box>
        </Box>

    )
};

export default QRCodeItem;