import React, { useEffect, useState } from 'react'
import CheckoutForm from '../pages/CheckOutForm/CheckOutForm'
import { loadStripe } from '@stripe/stripe-js';
import { Loader, getClientSecret } from '../utils/common';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { selectCurrentArtist } from '../redux/reducers/artistSlice';

const PaymentForm = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [amount, setAmount] = useState()
    const [isLoading, setIsLoading] = useState(true)

    // const stripe = useStripe();
    const [message, setMessage] = useState(null);
    const navigate = useNavigate()
    // const stripePromise = loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC');
    useEffect(() => {
        if (!localStorage.getItem("paymentCreate")) {
            navigate("/onboarding")
        } else {

        }

    }, [])

    useEffect(() => {
        if (localStorage.getItem("paymentCreate") && !localStorage.getItem("stripeInitialized")) {
            const initializeStripe = () => {
                console.log("initializeStripe")
                const stripePromise = loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC');
                setStripePromise(stripePromise)
                getClientSecret().then((r) => {
                    console.log("app.js client secret ", r)
                    setClientSecret(r.client_secret)
                    setAmount(r.amount)
                    setIsLoading(false)
                })
                    .catch((e) => {
                        console.log("error", e)
                    })
                localStorage.setItem("stripeInitialized", true)
            };
            initializeStripe()

        }

    }, [])

    if (isLoading) {
        return <Loader />
    }


    return (
        <div>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm amount={amount} clientSecret={clientSecret} />
            </Elements>
        </div>
    )
}

export default PaymentForm