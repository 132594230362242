import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { selectCurrentArtist } from "../../redux/reducers/artistSlice";
import { useAppSelector } from "../../redux/hooks";
import { styles } from '../CheckOutForm/style'
import { Box, Button, Typography } from "@mui/material";
import theme from "../../Theme";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../url";
import { Loader, currencyConvert } from "../../utils/common";
import { useSelector } from "react-redux";


export default function CheckOut() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(null);

    const currentArtist = useSelector(selectCurrentArtist);

    useEffect(() => {

        const getData = async () => {
            const exchangeAmount = await currencyConvert();

            setAmount(exchangeAmount);

            setIsLoading(false);
        }

        getData();

    }, [])

    useEffect(() => {
        if (!currentArtist) {
            navigate("/onboarding")
        }
    }, [currentArtist])

    // console.log("Current Artist in Checkout Form ==> ", currentArtist);

    const handleCoinbasePayment = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(API_BASE_URL + "coinbaseCharge", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: "Sound3 Starter Pack",
                    description: "Subscription for Sound3",
                    artist_id: currentArtist.artist.id,
                    album_id: currentArtist.album.album_id,
                    amount: amount,
                }),
            });

            const data = await response.json();

            if (response.status === 200) {
                window.open(data.data.hosted_url, "_self");
            } else {
                setIsLoading(false);
                console.log("Error in coinbase payment", data);
            }


        } catch (error) {
            console.log("error", error)
        }
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Box sx={styles.paymentFormBoxOuter}>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.primary.logoColor,
                    fontWeight: 500,
                    marginBottom: "20px",
                    fontSize: {
                        xs: '1.5rem',
                        sm: '1.5rem',
                        md: '1.5rem',
                        lg: '1.5rem',
                    },
                    textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'center',
                        lg: 'center',
                    },

                }}
            >
                Checkout using any of the following payment methods
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.primary.logoColor,
                    fontWeight: 500,
                    marginBottom: "20px",
                    fontSize: {
                        xs: '1rem',
                        sm: '1rem',
                        md: '1rem',
                        lg: '1rem',
                    },
                    textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'center',
                        lg: 'center',
                    },

                }}
            >
                Amount to be paid: {amount}
            </Typography>
            <Box sx={styles.paymentFormBox}>
                <Button
                    variant="contained"
                    sx={styles.submitBtn}
                    onClick={() => {
                        navigate("/checkOutFormStripe")
                    }}
                >
                    Pay via Stripe
                </Button>

                <Button
                    variant="contained"
                    sx={styles.submitBtn}
                    onClick={handleCoinbasePayment}
                >
                    Pay via Coinbase
                </Button>
            </Box>

        </Box>

    );
}