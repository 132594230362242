import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';
import { IMAGE_URL } from '../../url';

function DropItem({ album}) {
    // console.log("drop item", album)
    const convertDateFormat = (dateString) => {
        var dateObject = new Date(dateString);
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var month = months[dateObject.getMonth()];
        var day = dateObject.getDate();
        var year = dateObject.getFullYear();
        var hour = dateObject.getHours();
        var minute = dateObject.getMinutes();
        var second = dateObject.getSeconds();
        var ampm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12;
        hour = hour ? hour : 12;
        var formattedDate = month + " " + day + ", " + year + ", " + hour + ":" + (minute < 10 ? '0' : '') + minute + " " + ampm;
        return formattedDate;

    }

    return (
        <Box sx={styles.DropBox}>
            <Box sx={styles.DropLeftArea}>
                <Box sx={styles.DropImg}>
                    <Box
                        component={'img'}
                        src={album.album_image ? (IMAGE_URL + album.album_image) : ("assets/images/album.png")}
                        alt="Drop Image"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
                <Box sx={styles.DropContent}>
                    <Typography variant="h6" sx={styles.DropBoxTitle}>{album.album_name ? (album.album_name):("Album Name Not Available")}</Typography>
                    <Typography component={'p'} sx={styles.DropBoxSubTitle}>{!album.album_type ? (<><Icon icon="iconamoon:link-light" /> Album</>):("")}</Typography>
                    <Typography component={'p'} sx={styles.DropBoxSubTitle}>She’s Mine</Typography>
                    <Typography component={'span'} sx={styles.DropSubTIme}>{convertDateFormat(album.created_at)}</Typography>

                </Box>
            </Box>
            <Box sx={styles.DropRightArea}>
                <Button variant="contained" sx={styles.DropBtn}>
                    <Typography sx={styles.DropBoxBtnText}> See Revenue Earned</Typography>
                    <Box sx={styles.DropBtnIcon}>
                        <Icon icon="tabler:arrow-right" />
                    </Box>
                </Button>


            </Box>
        </Box>

    )
};

export default DropItem;