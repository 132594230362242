// import styled from '@emotion/styled';
// import { Avatar, Box, List, Typography, } from '@mui/material'
import { Box, Button, Container, TextField, Typography, FormControl, FormLabel, Select, MenuItem, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import { selectCurrentUser, updateCurrentUser } from "../../redux/reducers/userSlice";
import { setCurrentArtist, selectCurrentArtist, updateCurrentArtist } from "../../redux/reducers/artistSlice";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { Loader } from "../../utils/common";
import { API_BASE_URL, IMAGE_URL } from "../../url";
import { styles } from "./style"; 

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Onboarding = () => {

  const [onboardingInformation, setOnboardingInformation] = useState(false);
  const [artistInformation, setArtistInformation] = useState(false);
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null);

  // const [isTwitterSelected, setIsTwitterSelected] = useState(false);
  // const [isInstagramSelected, setIsInstagramSelected] = useState(false);

  const [artistLoginInformation, setArtistLoginInformation] = useState(null);

  const sliderOptions1 = {
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
  }
  

  const dispatch = useDispatch();

  const currentUser = useAppSelector(selectCurrentUser);

  const currentArtist = useAppSelector(selectCurrentArtist);
  // console.log(currentArtist)
  const navigate = useNavigate();

  const getAlbumImage = (album_image) => {
    if (album_image) {
      // console.log("albumImage", album_image);
      let coverImage = album_image.split('/');
      // console.log("coverImage ==> ", coverImage);
      // coverImage.splice(1, 0, "storage");
      coverImage.shift()
      const coverImageStr = coverImage.join('/');
      // console.log("coverImageStr ==> ", IMAGE_URL + coverImageStr);
      return IMAGE_URL + coverImageStr
    }

    return null
  }
  useEffect(()=>{
    localStorage.removeItem("stripeInitialized")
  },[])

  useEffect(() => {
    // console.log("currentUser in onboarding ==> ", currentUser);
    if (!currentUser) {
      // console.log("No user found");
      navigate('/');
    } else {
      setOnboardingInformation(true);

      const artistLogin = async () => {
        const formData = new FormData();
        formData.append("email", currentUser.email);
        formData.append("typeOfLogin", currentUser.typeOfLogin);

        try {
          const response = await fetch(API_BASE_URL + 'artistLogin', {
            method: "POST",
            body: formData,
          });

          // console.log("Response in artist login ==> ", response);

          if (!response.ok) {
            console.log("Error in fetching artist login information");
          } else {
            const responseData = await response.json();

            setArtistLoginInformation(responseData.data);

            // console.log("Response Data in artist login ==> ", responseData.data);

            if (responseData?.data?.artist) {
              dispatch(setCurrentArtist(responseData.data))

              setIsLoading(false);
            }
          }
        } catch (error) {
          // console.log("Error in fetching artist login information ==> ", error);
        }
      }

      // console.log("artistLoginInformation ==> ", artistLoginInformation, currentUser);

      if (!artistLoginInformation && currentUser && currentUser.email && currentUser.typeOfLogin) {
        artistLogin();
      }

    }
  }, [currentUser, navigate, artistLoginInformation, dispatch]);

  useEffect(() => {
    if (artistInformation)
      navigate('/artistInformation');
  }, [onboardingInformation, artistInformation, navigate])



  const [formData, setFormData] = useState({
    artistName: "",
    // socialMediaPlatform: "facebook",
    // facebookLink: "",
    // twitterLink: "",
    // instagramLink: "",
    profileImage:null
  });

  useEffect(() => {
    if (currentArtist) {
      if (currentArtist?.artist?.artist_name) {
        setFormData(prevFormData => ({
          ...prevFormData,
          artistName: currentArtist.artist.artist_name,
        }));
      }
      if (currentArtist?.artist?.picture) {
        // console.log("artist picture=== > ",currentArtist?.artist?.picture)
        setFormData(prevFormData => ({
          ...prevFormData,
          profileImage: getAlbumImage(currentArtist.artist.picture),
        }));
      }

      // if (currentArtist?.artist?.facebook_link) {
      //   setFormData(prevFormData => ({
      //     ...prevFormData,
      //     facebookLink: currentArtist.artist.facebook_link,
      //   }));
      // }

      // if (currentArtist?.artist?.twitter_link) {
      //   setFormData(prevFormData => ({
      //     ...prevFormData,
      //     twitterLink: currentArtist.artist.twitter_link,
      //   }));
      //   setIsTwitterSelected(true);
      // }

      // if (currentArtist?.artist?.instagram_link) {
      //   setFormData(prevFormData => ({
      //     ...prevFormData,
      //     instagramLink: currentArtist.artist.instagram_link,
      //   }));
      //   setIsInstagramSelected(true);
      // }
    }
  }, [currentArtist]);
  // console.log(formData)
  // useEffect(() => {
  //   if (formData.socialMediaPlatform === "twitter") {
  //     setIsTwitterSelected(true);
  //   }

  //   if (formData.socialMediaPlatform === "instagram") {
  //     setIsInstagramSelected(true);
  //   }

  //   // console.log("formData ==> ", formData);
  // }, [formData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const updatedErrors = {};

    setErrors(updatedErrors);
  };

  const handleBlur = (e) => {

    const updatedErrors = {};

    setErrors(updatedErrors);
  }

  const handleClick = () => {
    // console.log("FormData ==> ", formData);

    const updatedErrors = {};

    if (formData.artistName === "") {
      updatedErrors.artistName = "Artist/Band name is required";
      setErrors(updatedErrors);
      return;
    } else if (!selectedImage && !formData.profileImage) {
      updatedErrors.profileImage = "Artist/Band Image is required";
      setErrors(updatedErrors);
      return;
    } else {
      // setOnboardingInformation(false);
      // setArtistInformation(true);

      setIsLoading(true);

      const newFormData = new FormData();

      newFormData.append("artist_id", currentArtist.artist.id);
      newFormData.append("name", currentUser.name);
      newFormData.append("email", currentUser.email);
      newFormData.append("phone_number", "");
      newFormData.append("typeOfLogin", currentUser.typeOfLogin);
      newFormData.append("artist_name", formData.artistName);
      newFormData.append("stage_name", formData.artistName);
      if(selectedImage){
        newFormData.append("profileImage", selectedImage);
      }
      
      // newFormData.append("facebook_link", formData.facebookLink);
      // newFormData.append("twitter_link", formData.twitterLink);
      // newFormData.append("instagram_link", formData.instagramLink);
      newFormData.append("soundcloud_link", "");
      // console.log(newFormData)
      // for (var pair of newFormData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      addArtist(newFormData);

    }
  }
  const handleImageChange = (event) => {
    // console.log(event.target);
    const file = event.target.files[0];

    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (!acceptedFileTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid file type. Please select a JPEG, PNG or GIF file.',
      });
      return;
    }

    // console.log("file ===> ", file);

    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'File is too large, please select a file less than 5MB.',
      })
      return;
    }

    setSelectedImage(file);
    setErrors({ ...errors, profileImage: null })

  };
  const addArtist = async (data) => {
    // console.log("Data ==> ", data);
    try {
      const response = await fetch(API_BASE_URL + "addArtist", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        // console.log("Error in adding artist");

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })


      } else {
        const responseData = await response.json();
        // console.log("Response Data ==> ", responseData);

        // dispatch(updateCurrentUser(responseData.data));

        if (responseData?.data?.artist) {
          dispatch(updateCurrentArtist(responseData.data));
        }

        setOnboardingInformation(false);
        setArtistInformation(true);
      }
    } catch (error) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })

      // console.log("Error in adding artist ==> ", error);
    } finally {
      // setIsLoading(false);
    }

  }

  return (
    <>
      {isLoading ?
        <Loader /> :

        <Container maxWidth="xl"
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            sx={styles.onboardingInnerContainer}
          >
            {/* Slider Part  */}
            <Box sx={styles.sliderPart}>
              <Box>
                <Slider {...sliderOptions1}>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '55px',
                    }}
                  // className="revealator-zoomin revealator-duration15 revealator-delay8"
                  >
                    <Box
                      sx={styles.sliderItem}
                    >
                      <Box
                        sx={styles.sliderItemImageContainer}
                      >

                        <Box
                          component={"img"}
                          src={"/assets/images/slider1.png"} alt="Badge"
                          sx={styles.sliderItemImage}
                        />
                      </Box>

                      <Box sx={styles.sliderItemTextContainer}>
                        <Typography
                          sx={styles.sliderItemHeader}
                        >
                          Easily make your music available to your fans around the world
                        </Typography>
                        <Typography
                          sx={styles.sliderItemSubHeader}
                        >
                          Explore changes we’ve made.
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                </Slider>
              </Box>
            </Box>
            {/* Slider Part  end */}

            {/* form part */}

            <Box sx={styles.formPart}>
              <Box sx={styles.logoContainer}>
                <Box
                  component={"img"}
                  src={"/assets/images/logo.png"}
                  alt="Logo"
                  sx={styles.logo}
                />
              </Box>

              <Box sx={styles.formPartHeaderContainer}>
                <Typography sx={styles.formPartHeader}>
                  Let’s Get You Started
                </Typography>
                <Typography sx={styles.formPartSubHeader}>
                  Welcome! Set up your artist profile by filling the information below.
                </Typography>
              </Box>

              <Box component={'form'} sx={styles.formSection}>
                <Typography sx={styles.artistNameLable}>
                  Artists/Band name *
                </Typography>
                <TextField
                  className="inputArea"
                  sx={{
                    width: '100%',
                    fontSize: "22px",
                    borderRadius: '10px',
                  }}
                  fullWidth
                  id="fullWidth"
                  placeholder="Enter your artist/band name"
                  name="artistName"
                  value={formData.artistName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.artistName &&
                  <Typography sx={styles.artistNameError}>
                    {' * '}{errors.artistName}
                  </Typography>
                }
                {/* Social link */}
                {/* <Box
                  sx={{
                    width: '100%',
                    padding: '30px',
                    background: theme.palette.primary.Lightbg2,
                    borderRadius: '20px',
                    display: 'grid',
                    gap: '30px',
                  }}
                >
                  <Typography variant="h6" sx={{
                    color: theme.palette.primary.logoColor,
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "29px",
                    textAlign: "left",
                  }}>Social Links</Typography>
                  <Box sx={{
                    padding: '20px 20px 30px',
                    borderRadius: '20px',
                    border: '1px solid',
                    borderColor: theme.palette.primary.Lightbg3,
                  }}>
                    <FormControl fullWidth>
                      <FormLabel sx={{
                        marginBottom: '10px',
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "16px",
                          lg: "18px",
                        },
                      }} id="demo-row-radio-buttons-group-label">Social Platform</FormLabel>

                      <Select
                        className="cardInputArea"
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                            lg: '100%',
                          },
                          fontSize: "20px",
                          borderRadius: '10px',
                        }}
                        fullWidth
                        labelId="demo-social-media-select-label"
                        id="demo-social-media-select"
                        name="socialMediaPlatform"
                        value={formData.socialMediaPlatform}
                        label="Social Media"
                        displayEmpty
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={KeyboardArrowDownIcon}
                      >

                        <MenuItem value={"facebook"}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                          }}>
                            <img src="/assets/images/facebook.png" alt="facebook" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                            Facebook
                          </div>
                        </MenuItem>
                        <MenuItem value={"twitter"}><div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                        }}>
                          <img src="/assets/images/twitter.png" alt="twitter" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                          twitter
                        </div></MenuItem>
                        <MenuItem value={"instagram"}><div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                        }}>
                          <img src="/assets/images/instagram.png" alt="instagram" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                          instagram
                        </div></MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    padding: '20px 20px 30px',
                    borderRadius: '20px',
                    border: '1px solid',
                    borderColor: theme.palette.primary.Lightbg3,
                  }}>
                    <FormControl fullWidth>
                      <FormLabel sx={{
                        marginBottom: '10px',
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "16px",
                          lg: "18px",
                        },
                      }} id="demo-row-radio-buttons-group-label">Facebook Link</FormLabel>

                      <TextField className="cardInputArea" sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                          lg: '100%',

                        },
                        fontSize: "22px",
                        borderRadius: '10px',
                      }}
                        fullWidth
                        id="facebookLink"
                        placeholder="https://"
                        name="facebookLink"
                        value={formData.facebookLink}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>

                  {isTwitterSelected &&
                    <Box sx={{
                      padding: '20px 20px 30px',
                      borderRadius: '20px',
                      border: '1px solid',
                      borderColor: theme.palette.primary.Lightbg3,
                    }}>
                      <FormControl fullWidth>
                        <FormLabel sx={{
                          marginBottom: '10px',
                          color: theme.palette.primary.logoColor,
                          fontWeight: 600,
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                            lg: "18px",
                          },
                        }} id="demo-row-radio-buttons-group-label">Twitter Link</FormLabel>

                        <TextField className="cardInputArea" sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                            lg: '100%',

                          },
                          fontSize: "22px",
                          borderRadius: '10px',
                        }}
                          fullWidth
                          id="twitterLink"
                          placeholder="https://"
                          name="twitterLink"
                          value={formData.twitterLink}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Box>
                  }

                  {isInstagramSelected &&
                    <Box sx={{
                      padding: '20px 20px 30px',
                      borderRadius: '20px',
                      border: '1px solid',
                      borderColor: theme.palette.primary.Lightbg3,
                    }}>
                      <FormControl fullWidth>
                        <FormLabel sx={{
                          marginBottom: '10px',
                          color: theme.palette.primary.logoColor,
                          fontWeight: 600,
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                            lg: "18px",
                          },
                        }} id="demo-row-radio-buttons-group-label">Instagram Link</FormLabel>

                        <TextField className="cardInputArea" sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                            lg: '100%',

                          },
                          fontSize: "22px",
                          borderRadius: '10px',
                        }}
                          fullWidth
                          id="instagramLink"
                          placeholder="https://"
                          name="instagramLink"
                          value={formData.instagramLink}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Box>
                  }

                </Box> */}
                <FormControl fullWidth
                >
                  <FormLabel sx={styles.uploadImageLable} id="demo-row-radio-buttons-group-label">Upload image</FormLabel>
                  <Button
                    sx={styles.uploadImageButton}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                  // startIcon={<CloudUploadOutlinedIcon sx={{
                  //   fontSize: "50px !important",
                  //   color: theme.palette.primary.logoColor,
                  // }} />}
                  >
                    {formData.profileImage && !selectedImage &&
                      <Box sx={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                    }} component={"img"} src={`${formData.profileImage}`} />
                    }

                    {selectedImage &&
                      <Box sx={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'contain',
                      }} component={"img"} src={URL.createObjectURL(selectedImage)} />
                    }
                    <Box sx={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'contain',
                    }} component={"img"} src={"/assets/images/feather_upload-cloud.png"} />
                    {!selectedImage &&
                      <>
                        <Typography
                          variant="p"
                          component={"p"}
                          sx={styles.selectedImageDragDrop}
                        >
                          Drag & drop files or <span className="boldText">Browse</span>
                        </Typography>
                        <Typography
                          variant="p"
                          component={"p"}
                          sx={styles.selectedImageSupportingDocs}
                        >
                          Supported formats: JPEG, PNG, GIF.
                        </Typography>
                      </>
                    }
                    
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*.jpeg, image/*.png, image/*.gif"
                      onChange={handleImageChange}
                    />
                    {selectedImage && <Typography
                      variant="p"
                      component={"p"}
                      sx={styles.selectedImageName}
                    >
                      Selected Image: {selectedImage.name}
                    </Typography>}
                  </Button>
                </FormControl>
                {errors.profileImage &&
                  <Typography sx={styles.imageUploadError}>
                    {' * '}{errors.profileImage}
                  </Typography>
                }

                <Button
                  sx={styles.continueButton}
                  variant="contained"
                  onClick={handleClick}
                >Continue</Button>
              </Box>

            </Box>

            {/* form part end */}

          </Box>
        </Container>
      }

    </>
  );

};

export default Onboarding;
