import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';

function AnalyticsItem() {

    return (
        <Box sx={styles.DropBox}>
            <Box sx={styles.DropLeftArea}>
                <Box sx={styles.DropImg}>
                    <Box
                        component={'img'}
                        src={"/assets/images/music1.jpg"}
                        alt="Drop Image"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
                <Box sx={styles.DropContent}>
                    <Typography variant="h6" sx={styles.DropBoxTitle}>Bryan Lee</Typography>
                    <Typography component={'p'} sx={styles.DropBoxSubTitle}>She’s Mine</Typography>
                    <Box sx={styles.DropBoxInfoItem}>
                        <Typography component={'span'} sx={styles.DropBoxInfoText}>Total Mints</Typography>
                        <Typography component={'span'} sx={styles.DropBoxInfoValue}> - 0.0001</Typography>
                    </Box>
                    <Button variant="contained" sx={styles.ViewDataBtn}> View Data</Button>
                </Box>
            </Box>
            <Box component={'ul'} sx={styles.MintsUl}>
                <Box component={"li"} sx={styles.MintsLi}>
                    <Box
                        component={'img'}
                        src={"/assets/images/sound1.png"}
                        alt="sound1"
                        sx={styles.MintIcon} />
                    <Box sx={styles.MintInfo}>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Mints - </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> 0.0011</Typography>
                        </Box>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Earned -  </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> £600</Typography>
                        </Box>
                    </Box>

                </Box>
                <Box component={"li"} sx={styles.MintsLi}>
                    <Box
                        component={'img'}
                        src={"/assets/images/sound2.png"}
                        alt="sound1"
                        sx={styles.MintIcon} />
                    <Box sx={styles.MintInfo}>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Mints - </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> 0.0011</Typography>
                        </Box>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Earned -  </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> £600</Typography>
                        </Box>
                    </Box>

                </Box>
                <Box component={"li"} sx={styles.MintsLi}>
                    <Box
                        component={'img'}
                        src={"/assets/images/sound10.png"}
                        alt="sound1"
                        sx={styles.MintIcon} />
                    <Box sx={styles.MintInfo}>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Mints - </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> 0.0011</Typography>
                        </Box>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Earned -  </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> £600</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box component={"li"} sx={styles.MintsLi}>
                    <Box
                        component={'img'}
                        src={"/assets/images/sound7.png"}
                        alt="sound1"
                        sx={styles.MintIcon} />
                    <Box sx={styles.MintInfo}>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Mints - </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> 0.0011</Typography>
                        </Box>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Earned -  </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> £600</Typography>
                        </Box>
                    </Box>

                </Box>
                <Box component={"li"} sx={styles.MintsLi}>
                    <Box
                        component={'img'}
                        src={"/assets/images/sound5.png"}
                        alt="sound1"
                        sx={styles.MintIcon} />
                    <Box sx={styles.MintInfo}>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Mints - </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> 0.0011</Typography>
                        </Box>
                        <Box sx={styles.MintInfoItem}>
                            <Typography component={'span'} sx={styles.MintInfoText}>Earned -  </Typography>
                            <Typography component={'span'} sx={styles.MintInfoValue}> £600</Typography>
                        </Box>
                    </Box>

                </Box>

            </Box>
            <Box sx={styles.ButtonGroups}>
                <Button variant="contained" sx={styles.DataBtn}> View Data</Button>
                <Button variant="contained" sx={styles.MintsBtn}> View Mint</Button>
            </Box>
        </Box>


    )
};

export default AnalyticsItem;