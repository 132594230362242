import React, { useEffect } from "react"

import { Box, Container, Typography } from "@mui/material"
import theme from "../Theme"
import { initNufiDappCardanoSdk } from "@nufi/dapp-client-cardano"
import nufiCoreSdk from "@nufi/dapp-client-core"
import { getApi, getSocialInfoFromLocalStorage, isLocalStorageAvailable } from "../utils"
import { useDisclosure } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import {
  setCurrentUser,
  clearCurrentUser,
  selectCurrentUser,
} from "../redux/reducers/userSlice"
import { useAppSelector, useAppDispatch } from "../redux/hooks"
import { useLocation, useNavigate } from "react-router-dom"
import { prepareAutoBatched } from "@reduxjs/toolkit"

// import { IMAGE_URL } from "../url";

// social info object look like this:
//
// {
//      "email": "my@gmail.com",
//      "name": "Johnny",
//      "profileImage": "https://lh3.googleusercontent.com/a/abczcy=s96-c",
//      "typeOfLogin": "google"
//  }
//

const Header = () => {
  const [ssoUserInfo, setSSOUserInfo] = React.useState(null)
  const [isConnecting, setIsConnecting] = React.useState(false)
  const [socialInfo, setSocialInfo] = React.useState(null)
  const [localStorageAvailable, _] = React.useState(isLocalStorageAvailable())
  const [blockchainNetwork, __] = React.useState(process.env.REACT_APP_BLOCKCHAIN_NETWORK)

  const navigate = useNavigate()

  const location = useLocation()

  //*** Redux Dispatch */
  const dispatch = useDispatch()

  // Note that this does not represent the currently connected wallet. Instead
  // it represents that wallet that user chooses during Connect wallet process.
  const [_candidateWalletName, _setCandidateWalletName] = React.useState("nufi")

  const walletNotFound = useDisclosure()
  const wrongNetwork = useDisclosure()
  const cancelRefWalletNotFound = React.useRef(null)
  const cancelRefWrongNetwork = React.useRef(null)

  // check for any cardano browser wallet installed
  const hasWalletExtension = (walletName) => !!window.cardano?.[walletName]

  const onJoinWaitlist = () => {
    _setCandidateWalletName("nufiSSO")
    initNufiDappCardanoSdk(nufiCoreSdk, "sso")
    connectWallet("nufiSSO")
  }

  const connectWallet = async (walletName) => {
    if (!hasWalletExtension(walletName)) {
      walletNotFound.onOpen()
    } else {
      try {
        setIsConnecting(true)
        const api = await getApi(walletName)
        const networkId = await api.getNetworkId()

        // 0 is the preprod network id, for mainnet it will be 1
        if (networkId !== 0) {
          wrongNetwork.onOpen()
        } else {
          _setCandidateWalletName(walletName)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsConnecting(false)
      }
    }
  }

  useEffect(() => {
    let _socialInfo = null

    if (localStorageAvailable) {
      _socialInfo = getSocialInfoFromLocalStorage(blockchainNetwork)
    }

    if (blockchainNetwork === "preprod") {
      nufiCoreSdk.init("https://wallet-testnet-staging.nu.fi")
    } else {
      //TODO add NUFI mainnet url once available
    }
    // console.log("socialInfo ===> ", socialInfo);

    if (localStorageAvailable) {
      if (_socialInfo) {
        ; (async () => {
          initNufiDappCardanoSdk(nufiCoreSdk, "sso")
          let nufi = await window.cardano.nufiSSO.enable()
          // console.log(window.cardano.nufiSSO)
          setSocialInfo(_socialInfo)
        })()
      }
    }

    // Listen for SSO session info
    const currentSSOInfo = nufiCoreSdk.getApi().onSocialLoginInfoChanged((data) => {
      // send this info to our back-end
      // ...
      setSSOUserInfo(data)

      if (localStorageAvailable) {
        localStorage.setItem(
          `nufi-social-login-${blockchainNetwork}`,
          JSON.stringify(data)
        )
      }
    })
    setSSOUserInfo(currentSSOInfo)

    // store and retrieve information about signed up user in local storage
    const hasSignedUp = false

    const fn = async () => {
      // show widget by next page refresh
      if (hasSignedUp) {
        initNufiDappCardanoSdk(nufiCoreSdk, "sso")
        let nufi = await window.cardano.nufiSSO.enable()
      }
    }
    fn()

    setSocialInfo(_socialInfo)
  }, [])

  // console.log("Social Info ===> ", socialInfo);

  // console.log("SSO User Info ===> ", ssoUserInfo);

  const currentUser = useAppSelector(selectCurrentUser)

  useEffect(() => {
    console.log("currentUser in header ==> ", currentUser);
    if (currentUser) {
      if (location.pathname === "/") {
        navigate("/onboarding")
      } else if (location.pathname === "/fanLogin") {
        navigate("/fansDashboard")
      }

      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    }
  }, [currentUser])

  useEffect(() => {
    if (socialInfo) {
      const socialInfoObj = JSON.parse(socialInfo)
      setSocialInfo(socialInfoObj)
      // console.log("Social Info ===> ", socialInfoObj);
      dispatch(setCurrentUser(socialInfoObj))
    } else if (ssoUserInfo) {
      const ssoUserInfoObj = ssoUserInfo
      // console.log("sso Info ===> ", ssoUserInfo);
      dispatch(setCurrentUser(ssoUserInfoObj))
    } else {
      localStorage.removeItem("currentUser")
      localStorage.removeItem(`nufi-social-login-${blockchainNetwork}`)
      dispatch(clearCurrentUser())
    }
  }, [socialInfo, ssoUserInfo, dispatch])
  // console.log("Social Info open ===> ", socialInfo);
  return (
    <Container maxWidth="xl" sx={{ height: "100%" }}>
      <Box
        sx={{
          padding: "33px 0px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="#"
          component={"a"}
          className="revealator-slideright revealator-duration15 revealator-delay5"
        >
          <Box
            sx={{
              width: {
                xs: "72px",
                sm: "72px",
                md: "100px",
                lg: "140px",
              },
              height: {
                xs: "22px",
                sm: "22px",
                md: "35px",
                lg: "43px",
              },
              objectFit: "contain",
            }}
            component={"img"}
            src={"/assets/images/logo.png"}
            alt="Logo"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: '100%'
          }}
          className="revealator-zoomin revealator-duration15 revealator-delay1"
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: {
                xs: "159px",
                sm: "159px",
                md: "350px",
                lg: "393px",
              },
              borderRadius: "40px",
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "16px",
                lg: "16px",
              },
              fontWeight: "700",
              lineHeight: "23.12px",
              color: theme.palette.primary.logoColor,
              backgroundColor: theme.palette.primary.Lightbg,
              padding: {
                xs: "12.5px 23px",
                sm: "12.5px 23px",
                md: "24px 52px",
                lg: "24px 52px",
              },
              textAlign: "center",
            }}
          >
            Welcome to SOUND³
          </Typography>
        </Box>

        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
            },
            visibility: socialInfo ? "hidden" : "visible",
          }}
          component={"a"}
          href="#"
          className="revealator-slideleft revealator-duration15 revealator-delay5"
          onClick={onJoinWaitlist}
        >
          <Box
            component={"img"}
            src={"/assets/images/SocialButtonWhite.png"}
            alt="Google"
            sx={{
              width: {
                xs: "36px",
                sm: "36px",
              },
            }}
          />
        </Box>


        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
            },
            alignItems: "center",
            backgroundColor: theme.palette.primary.white,
            borderRadius: "39px",
            border: `1px solid ${theme.palette.primary.logoColor}`,
            boxShadow:
              "0px 0px 0px 4px rgba(242, 244, 247, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            padding: "10px 16px",
            visibility: socialInfo ? "hidden" : "visible",
          }
          }
          component={"a"}
          href="#"
          className="revealator-slideleft revealator-duration15 revealator-delay5"
          onClick={onJoinWaitlist}
        >
          <Box
            component={"img"}
            src={"/assets/images/Social button.png"}
            alt="Google"
            sx={{
              width: "48px",
            }}
          />

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              paddingLeft: "12px",
              color: theme.palette.primary.logoColor,
            }}
          >
            Join the waiting list
          </Typography>
        </Box>

      </Box>
    </Container >

    // </Box>
  )
}

export default Header
