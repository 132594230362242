import React, { useEffect, useState } from 'react'
import { styles } from './style'
import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material'
import theme from "../../Theme";
import { Icon } from '@iconify/react';
import FansHeader from '../../components/FansHeader';
import axios from 'axios';
import PropTypes from 'prop-types';
import DropItem from '../../components/DropItem/DropItem';
import AnalyticsItem from '../../components/AnalyticsItem/AnalyticsItem';
import QRCodeItem from '../../components/QRCodeItem/QRCodeItem';
import { API_BASE_URL } from '../../url';
import Swal from 'sweetalert2';
import { Loader } from "../../utils/common";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentUser } from '../../redux/reducers/userSlice';


const ArtistDashboard = () => {
    const [value, setValue] = useState(0);
    const [artistData, setArtistData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const currentUser = useAppSelector(selectCurrentUser);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getArtistAlbumData=async(id)=>{
        
        try {
            const response = await fetch(API_BASE_URL + `artistAlbums/${id}`, {
                method: "GET",
            });

            if (!response.ok) {
                // console.log("Error in adding album track ==>", response);

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
                setIsLoading(false)

            } else {
                const responseData = await response.json();
                console.log("Response Data getArtistAlbumData ==> ", responseData);
                setArtistData(responseData.data)
                setIsLoading(false)

                // dispatch(updateCurrentUserAlbum(responseData?.data?.album));


            }
        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setIsLoading(false)
            // console.log("Error in adding album ==> ", error);
        }
    }
    const getArtistData = async () => {
        // console.log("getArtistData...")
        console.log("getArtistData currentUser===> ", localStorage.getItem("currentUser"))
        const user = JSON.parse(localStorage.getItem("currentUser"))
        // console.log("user", user)
        try {
            const responseData = await axios.post(API_BASE_URL + "artistLogin", {email:user.email,typeOfLogin:user.typeOfLogin})

            
            if (!responseData.status) {
                // console.log("Error in adding album track ==>", responseData);

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
                // setArtistData(null)
                setIsLoading(false)

            } else {
                // const responseData = await response.json();
                // console.log("Response Artist Data ==> ", responseData);
                setArtistData(responseData.data)
                getArtistAlbumData(responseData.data.data.artist.id)
                setIsLoading(false)

                // dispatch(updateCurrentUserAlbum(responseData?.data?.album));


            }
        } catch (error) {

            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!',
            // })
            // setArtistData(null)
            setIsLoading(false)
            // console.log("Error in adding album ==> ", error);
        }
    }

    useEffect(() => {
        // getArtistAlbumData()
        // console.log("useeffect ")
        getArtistData()
    }, [currentUser])

    return (
        <>
            {
                isLoading ?
                    <Loader /> :
                    <Box>
                        {/* <SwapModal /> */}
                        <Box sx={styles.headerSection}>
                            <Container maxWidth="lg">
                                <FansHeader getArtistData={getArtistData} artist={artistData?.artist} />
                            </Container>
                        </Box>
                        <Box sx={styles.MainRow}>
                            <Box sx={styles.sideDashboard}>
                                <Box sx={styles.dashBoardBx}>
                                    <Box sx={styles.dashBoardIconBox}>
                                        <Icon icon="mage:dashboard-fill" />
                                    </Box>
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.logoColor }}>Dashboard</Typography>
                                </Box>
                            </Box>
                            <Container maxWidth="xl" sx={styles.XlContainer}>
                                <Box sx={styles.mainTitle}>
                                    <Typography variant="h6" sx={styles.DashTitle}>Good Morning, {artistData?.artist?.first_name} 🌤️</Typography>
                                    <Typography component={'p'} sx={styles.DashSubTitle}>Take a look at what’s happening with your collections today!</Typography>
                                </Box>
                                <Button sx={styles.DropNewMusicBtn1} onClick={() => navigate("/artistInformation")} variant="outlined">Drop New Music</Button>
                                <Box sx={styles.CollectionsBox}>
                                    <Box sx={{ width: '100%', position: 'relative', }}>
                                        <Button sx={styles.DropNewMusicBtn} variant="outlined">Drop New Music</Button>
                                        <Box className={'MUsictabs'} sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            "Mui-selected": {
                                                color: theme.palette.primary.logoColor,
                                                borderBottom: `2px solid ${theme.palette.primary.logoColor}`,
                                            }

                                        }}>
                                            <Tabs className={'sbtabs'} value={value} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab sx={styles.MyTabTitle} label="Drops" {...a11yProps(0)} />
                                                <Tab sx={styles.MyTabTitle} label="Analytics" {...a11yProps(1)} />
                                                <Tab sx={styles.MyTabTitle} label="QR Code" {...a11yProps(2)} />
                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel sx={styles.TabPanal} value={value} index={0}>
                                            <Box sx={styles.DropsMusic}>
                                                {/* comingsoon sec  */}

                                                {/* <Box sx={styles.comingSoonBox}>
                                        <Box sx={styles.comingSoonImg}>
                                            <Box
                                                component={'img'}
                                                src={"/assets/images/comingsoon.png"}
                                                alt="No Music Drops Yet!"
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Box>
                                        <Typography variant="h6" sx={styles.comingSoonText}>No Music Drops Yet!</Typography>
                                        <Typography component={'p'} sx={styles.comingSoonSubText}>You haven’t released any music yet. Start by creating a new drop.</Typography>
                                        <Button variant="contained" sx={styles.comingSoonBtn}>Drop New Music</Button>
                                    </Box> */}

                                                {/* DropBox items  */}
                                                {artistData?.albums?.map((album,i) => (
                                                    <DropItem key={i} album={album} artist={artistData.artist} />
                                                ))}

                                                {/* <DropItem />
                                                <DropItem />
                                                <DropItem /> */}

                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={1}>
                                            <Box sx={styles.AnalyticsBoxOuter}>
                                                <AnalyticsItem />
                                                <AnalyticsItem />
                                                <AnalyticsItem />
                                                <AnalyticsItem />
                                            </Box>
                                        </CustomTabPanel>
                                        <CustomTabPanel value={value} index={2}>
                                            <Box sx={styles.QRCodeBoxOuter}>
                                                <QRCodeItem />
                                                <QRCodeItem />
                                                <QRCodeItem />
                                                <QRCodeItem />
                                                <QRCodeItem />
                                            </Box>
                                        </CustomTabPanel>
                                    </Box>
                                </Box>

                                <Box sx={styles.CollectionsBox}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={styles.UpcomingTitle} variant='h5'>Upcoming Events</Typography>
                                        <Box sx={styles.DropsMusic}>
                                            {/* comingsoon sec  */}

                                            <Box sx={styles.comingSoonBox}>
                                                <Box sx={styles.comingSoonImg}>
                                                    <Box
                                                        component={'img'}
                                                        src={"/assets/images/comingsoon.png"}
                                                        alt="No Music Drops Yet!"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                                <Typography variant="h6" sx={styles.comingSoonText}>No Events Yet!</Typography>
                                                <Typography component={'p'} sx={styles.comingSoonSubText}>Lorem ipsum dolor sit amet consectetur. Vel in justo sollicitudin eget ac adipiscing porttitor. Elementum nulla.</Typography>
                                                {/* <Button variant="contained" sx={styles.comingSoonBtn}>Drop New Music</Button> */}
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box>

                            </Container>
                        </Box>
                    </Box >
            }
        </>
    )

}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default ArtistDashboard