import { Box, Button, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../Theme";
import { styled } from '@mui/material/styles';
import TrackNo from "./TrackNo";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { updateCurrentArtist, updateCurrentArtistAlbum } from "../redux/reducers/artistSlice";
import { IMAGE_URL, API_BASE_URL } from "../url";
import { formatTimeAddSemicolon } from "../utils/common";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



const ArtistInformationFormSingle = ({ formData, setFormData, setPackageInformation, currentUser, currentArtist, singleTrack, setSingleTrack, setIsLoading, errors, setErrors }) => {

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [trackDuration, setTractDuration] = useState(null)
  const dispatch = useDispatch();

  // console.log("formData in ArtistInformationForm Single ===> ", formData);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData({ ...formData, [name]: value });

    if (name === "songDuration") {
      setError(false);
      setErrorText(null);
    }

    const updatedErrors = {};

    setErrors(updatedErrors);

  }

  // console.log('track single ===> ', singleTrack);

  const handleNextClick = () => {
    // console.log("formData in handleNextClick ===> ", formData);
    const updatedErrors = {};
    console.log("single Track =>>> ", singleTrack)
    if (selectedTrack === null && singleTrack === null) {
      updatedErrors.song = "Please select a track";
      setErrors(updatedErrors);
      return;
    } else if (formData.songTitle === "") {
      updatedErrors.songTitle = "Please enter song title";
      setErrors(updatedErrors);
      return;
    }
    // else if (formData.songDuration === "") {
    //   updatedErrors.songDuration = "Please enter song duration";
    //   setErrors(updatedErrors);
    //   return;
    // } 
    else if (!formData.bio) {
      updatedErrors.bio = "Please enter bio";
      setErrors(updatedErrors);
      return;
    }
    else {

      setIsLoading(true);

      let newTrack = [{
        track_no: 1,
        track_name: formData.songTitle,
        track_duration: trackDuration,
        track_url: singleTrack
      }];

      // console.log('selectedImage ===> ', selectedImage);

      const newFormData = new FormData();
      newFormData.append('artist_id', currentArtist?.artist.id);
      if (currentArtist?.album?.album_id) {
        newFormData.append('album_id', currentArtist?.album?.album_id);
      }
      newFormData.append('album_type', '0');
      newFormData.append('album_image', selectedImage);

      if (selectedTrack) {
        newFormData.append('track_file[0]', selectedTrack);
      }

      if (singleTrack) {
        // console.log('singleTrack ===> ', singleTrack);
        newTrack[0].track_url = singleTrack;
      }

      newFormData.append('bio', formData.bio);

      newFormData.append('tracks', JSON.stringify(newTrack));
      newFormData.append('genre', formData.genre);
      newFormData.append('music_metadata', formData.musicMetadataVersion);
      newFormData.append('copyright_holder', formData.copyrightHolder);

      // for (var pair of newFormData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      addAlbumTrack(newFormData);
    }

  }

  const addAlbumTrack = async (data) => {
    // for (const [key, value] of data.entries()) {
    //   console.log(key, value);
    // }
    // console.log(add)
    try {
      const response = await fetch(API_BASE_URL + "addAlbumTrack", {
        method: "POST",
        body: data,
      });

      // console.log("Response in adding album track ==>", response);

      if (!response.ok) {
        console.log("Error in adding album track ==>", response);

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })


      } else {
        const responseData = await response.json();
        // console.log("Response Data ==> ", responseData);

        if (responseData?.data?.album) {
          dispatch(updateCurrentArtistAlbum(responseData.data));
        }

        // dispatch(updateCurrentUserAlbum(responseData?.data?.album));

        setPackageInformation(true);
      }
    } catch (error) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })

      // console.log("Error in adding album ==> ", error);
    } finally {
      // setIsLoading(false);
    }
  }

  const handleImageChange = (event) => {
    // console.log(event.target);
    const file = event.target.files[0];

    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (!acceptedFileTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid file type. Please select a JPEG, PNG or GIF file.',
      });
      return;
    }

    // console.log("file ===> ", file);

    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'File is too large, please select a file less than 5MB.',
      })
      return;
    }

    setSelectedImage(file);

  };

  const handleBlur = () => {
    //const regex = /^(\d{2}):(\d{2})$/;
    //if (!regex.test(formData.songDuration)) {
    //  setError(true);
    //  setErrorText('Invalid duration format. Please use mm:ss.');
    //}

    const updatedErrors = {};

    setErrors(updatedErrors);
  };

  const handleTrackChange = (event) => {
    // console.log(event.target);
    const file = event.target.files[0];
    // console.log(file)

    if (file) {
      const audioElement = new Audio(URL.createObjectURL(file));
      audioElement.addEventListener('loadedmetadata', () => {
        const durationInSeconds = audioElement.duration;
        const durationInMinutes = durationInSeconds / 60
        const durationRounded = durationInMinutes.toFixed(2);
        setTractDuration(durationRounded)
        console.log(durationRounded);
      });
    }

    const acceptedFileTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];

    if (!file) {
      return;
    }

    if (!acceptedFileTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid file type. Please select an MP3, WAV or OGG file.',
      });
      return;
    }

    // console.log("file ===> ", file);

    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'File is too large, please select a file less than 10MB.',
      })
      return;
    }

    setSelectedTrack(file);

    const updatedErrors = {};

    setErrors(updatedErrors);

  };


  return (
    <>
      <FormControl fullWidth
      >
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Upload cover image</FormLabel>
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '445px',
              lg: '445px',

            },
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '0px',
            color: theme.palette.primary.logoColor,
            background: 'transparent !important',
            borderRadius: "20px",
            padding: "8px 24px 8px 8px",
            textTransform: "capitalize",
            border: "1px dashed",
            boxShadow: 'none !important',
            borderColor: theme.palette.primary.logoColor,

            "&:hover": {
              borderColor: 'transparent',
              color: theme.palette.primary.logoColor,
              border: "1px dashed",
              background: 'transparent !important',
              boxShadow: 'none !important',
            }
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        // startIcon={<CloudUploadOutlinedIcon sx={{
        //   fontSize: "50px !important",
        //   color: theme.palette.primary.logoColor,
        // }} />}
        >
          {formData.albumImage && !selectedImage &&
            <Box sx={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
            }} component={"img"} src={`${formData.albumImage}`} />
          }

          {selectedImage &&
            <Box sx={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
            }} component={"img"} src={URL.createObjectURL(selectedImage)} />
          }
          <Box sx={{
            width: '50px',
            height: '50px',
            objectFit: 'contain',
          }} component={"img"} src={"/assets/images/feather_upload-cloud.png"} />

          {!selectedImage &&
            <>
              <Typography
                variant="p"
                component={"p"}
                sx={{
                  color: theme.palette.primary.LightText,
                  fontWeight: 400,
                  textAlign: 'center',
                  width: '100%',
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "18px",
                    lg: "18px",
                  },
                }}
              >
                Drag & drop files or <span className="boldText">Browse</span>
              </Typography>
              <Typography
                variant="p"
                component={"p"}
                sx={{
                  color: theme.palette.primary.LightText,
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                  },
                  textAlign: 'center',
                }}
              >
                Supported formats: JPEG, PNG, GIF.
              </Typography>
            </>
          }

          <VisuallyHiddenInput
            type="file"
            accept="image/*.jpeg, image/*.png, image/*.gif"
            onChange={handleImageChange}
          />
          {selectedImage && <Typography
            variant="p"
            component={"p"}
            sx={{
              color: theme.palette.primary.LightText,
              fontWeight: 400,
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "14px",
                lg: "14px",
              },
            }}
          >
            Selected Image: {selectedImage.name}
          </Typography>}
        </Button>
      </FormControl>

      <FormControl fullWidth
      >
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Upload Song *</FormLabel>
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '445px',
              lg: '445px',

            },
            height: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '5px',
            color: theme.palette.primary.logoColor,
            background: 'transparent !important',
            borderRadius: "20px",
            padding: "8px 24px 8px 8px",
            textTransform: "capitalize",
            border: "1px dashed",
            boxShadow: 'none !important',
            borderColor: theme.palette.primary.logoColor,

            "&:hover": {
              borderColor: 'transparent',
              color: theme.palette.primary.logoColor,
              border: "1px dashed",
              background: 'transparent !important',
              boxShadow: 'none !important',
            }
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        // startIcon={<CloudUploadOutlinedIcon sx={{
        //   fontSize: "50px !important",
        //   color: theme.palette.primary.logoColor,
        // }} />}
        >
          <Box sx={{
            width: '50px',
            height: '50px',
            objectFit: 'contain',
          }} component={"img"} src={"/assets/images/musicFile.png"} />
          {!selectedTrack &&
            <>

              <Typography
                variant="p"
                component={"p"}
                sx={{
                  color: theme.palette.primary.LightText,
                  fontWeight: 400,
                  textAlign: 'center',
                  width: '100%',
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "18px",
                    lg: "18px",
                  },
                }}
              >
                Drag & drop <span className="boldText">Song</span>
              </Typography>

            </>
          }

          <VisuallyHiddenInput
            type="file"
            accept="audio/*.mp3, audio/*.wav, audio/*.ogg"
            onChange={handleTrackChange}
          />

          {selectedTrack && <Typography
            variant="p"
            component={"p"}
            sx={{
              color: theme.palette.primary.LightText,
              fontWeight: 400,
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "14px",
                lg: "14px",
              },
            }}
          >
            Selected Track: {selectedTrack.name}
          </Typography>}

        </Button>
        {errors.song && <Typography sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.errorText,
          marginTop: '10px',
        }}>
          {' * '}{errors.song}
        </Typography>}
      </FormControl>


      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Song Title *</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="songTitle"
          placeholder="Enter song tile"
          name="songTitle"
          value={formData.songTitle}
          onChange={handleFormDataChange}
        />

        {errors.songTitle && <Typography sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.errorText,
          marginTop: '10px',
        }}>
          {' * '}{errors.songTitle}
        </Typography>}
      </FormControl>


      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Bio *</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="bio"
          name="bio"
          placeholder="Enter bio"
          value={formData.bio}
          onChange={handleFormDataChange}
        />

        {errors.bio && <Typography sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.errorText,
          marginTop: '10px',
        }}>
          {' * '}{errors.bio}
        </Typography>}

      </FormControl>

      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Genre</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="genre"
          name="genre"
          placeholder="Enter genre"
          value={formData.genre}
          onChange={handleFormDataChange}
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Copyright Holder</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="copyrightHolder"
          name="copyrightHolder"
          placeholder="Enter copyright holder"
          value={formData.copyrightHolder}
          onChange={handleFormDataChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Music Metadata Version</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="musicMetadataVersion"
          name="musicMetadataVersion"
          placeholder="Enter version"
          value={formData.musicMetadataVersion}
          onChange={handleFormDataChange}
        />
      </FormControl>
      <Button
        sx={{
          width: 'auto',
          minWidth: '160px',
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.white,
          padding: '20px 32px 20px 32px',
          marginTop: '50px',
          background: theme.palette.primary.logoColor,
          borderRadius: '10px',
          textTransform: 'capitalize',
          height: '50px',
          boxShadow: '0px 8px 15px 1px #2F2E4159',
          "&:hover": {
            background: theme.palette.primary.logoColor,
            boxShadow: '0px 8px 15px 1px #2F2E4159',
            color: theme.palette.primary.white,
          }
        }}
        variant="contained"
        onClick={handleNextClick}
      >Next</Button>
    </>
  )
}

const ArtistInformationFormAlbum = ({ formData, setFormData, setPackageInformation, currentUser, currentArtist, multipleTracks, setMultipleTracks, initialTracksLength, setIsLoading, errors, setErrors }) => {

  // console.log("formData in ArtistInformationForm Album ===> ", formData);

  const [trackNumber, setTrackNumber] = useState(1);

  const [selectedAlbumImage, setSelectedAlbumImage] = useState(null);
  const [selectedTracks, setSelectedTracks] = useState({});

  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formData.tracks.length > 0) {
      setTrackNumber(formData.tracks.length);
    }
  }, [formData.tracks]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    const updatedErrors = {};

    setErrors(updatedErrors);

  }

  // console.log('multipleTracks ===> ', multipleTracks);

  const handleBlur = () => {
    const updatedErrors = {};

    setErrors(updatedErrors);
  };

  const handleNextClick = () => {
    // console.log("formData in handleNextClick Album ===> ", formData);

    const updatedErrors = {};

    for (let i = 0; i < trackNumber; i++) {
      if (formData.tracks[i].track_name === "") {
        updatedErrors[i] = {
          track_name: '* Required'
        };
        setErrors(updatedErrors);
        return;
      } else if (formData.tracks[i].track_duration === "") {
        updatedErrors[i] = {
          track_duration: '* Required'
        };
        setErrors(updatedErrors);
        return;
      } else if (!selectedTracks[i] && !multipleTracks[i]) {
        updatedErrors[i] = {
          track_file: '* Required'
        };
        setErrors(updatedErrors);
        return;
      }
    }

    if (formData.albumName === "") {
      updatedErrors.albumName = "Please enter album name";
      setErrors(updatedErrors);
      return;
    } else if (formData.bio === "") {
      updatedErrors.bio = "Please enter bio";
      setErrors(updatedErrors);
      return;
    }
    else {

      setIsLoading(true);

      let allTracks = formData.tracks.map((track, index) => {
        return {
          track_no: index + 1,
          track_name: track.track_name,
          track_duration: track.track_duration,
          track_url: ""
        };
      });

      // console.log("multipleTracks ===> ", multipleTracks);

      // console.log("allTracks ===> ", allTracks);

      // console.log("Initial Tracks Length ===> ", initialTracksLength);

      const newFormData = new FormData();
      newFormData.append('artist_id', currentArtist?.artist.id);
      if (currentArtist?.album?.album_id) {
        newFormData.append('album_id', currentArtist?.album?.album_id);
      }
      newFormData.append('album_type', '1');
      newFormData.append('album_image', selectedAlbumImage);


      if (Object.keys(selectedTracks).length > 0) {
        for (let i = 0; i < trackNumber; i++) {
          // console.log("selectedTracks ===> ", selectedTracks[i]);
          if (selectedTracks[i]) {
            newFormData.append(`track_file[${i}]`, selectedTracks[i]);
          }
        }
      }

      if (initialTracksLength && Object.keys(multipleTracks).length > 0) {
        for (let i = 0; i < initialTracksLength; i++) {
          // console.log("selectedTracks ===> ", selectedTracks[i]);
          if (multipleTracks[i]) {
            // console.log("allTracks ===> ", allTracks);
            allTracks[i].track_url = multipleTracks[i];
          }
        }
      }

      newFormData.append('tracks', JSON.stringify(allTracks));

      newFormData.append('album_name', formData.albumName);

      newFormData.append('genre', formData.genre);
      newFormData.append('music_metadata', formData.musicMetadataVersion);
      newFormData.append('copyright_holder', formData.copyrightHolder);
      newFormData.append('bio', formData.bio);

      // for (var pair of newFormData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      addAlbumTrack(newFormData);

      // setPackageInformation(true);
    }

  }

  const handleFileChange = (event) => {
    // console.log(event.target);
    const file = event.target.files[0];

    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (!acceptedFileTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid file type. Please select a JPEG, PNG or GIF file.',
      });
      return;
    }

    // console.log("file ===> ", file);

    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'File is too large, please select a file less than 5MB.',
      })
      return;
    }

    setSelectedAlbumImage(file);

  };

  const addAlbumTrack = async (data) => {
    try {
      const response = await fetch(API_BASE_URL + "addAlbumTrack", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        // console.log("Error in adding album track ==>", response);

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })


      } else {
        const responseData = await response.json();
        // console.log("Response Data ==> ", responseData);

        if (responseData?.data?.album) {
          dispatch(updateCurrentArtistAlbum(responseData.data));
        }

        // dispatch(updateCurrentUserAlbum(responseData?.data?.album));

        setPackageInformation(true);
      }
    } catch (error) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })

      // console.log("Error in adding album ==> ", error);
    } finally {
      // setIsLoading(false);
    }
  }

  // console.log("selectedTracks ===> ", selectedTracks);

  return (
    <>
      <FormControl fullWidth
      >
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Upload image</FormLabel>
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '445px',
              lg: '445px',

            },
            height: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '0px',
            color: theme.palette.primary.logoColor,
            background: 'transparent !important',
            borderRadius: "20px",
            padding: "8px 24px 8px 8px",
            textTransform: "capitalize",
            border: "1px dashed",
            boxShadow: 'none !important',
            borderColor: theme.palette.primary.logoColor,

            "&:hover": {
              borderColor: 'transparent',
              color: theme.palette.primary.logoColor,
              border: "1px dashed",
              background: 'transparent !important',
              boxShadow: 'none !important',
            }
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        // startIcon={<CloudUploadOutlinedIcon sx={{
        //   fontSize: "50px !important",
        //   color: theme.palette.primary.logoColor,
        // }} />}
        >
          {formData.albumImage && !selectedAlbumImage &&
            <Box sx={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
            }} component={"img"} src={`${formData.albumImage}`} />
          }

          {selectedAlbumImage &&
            <Box sx={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
            }} component={"img"} src={URL.createObjectURL(selectedAlbumImage)} />
          }

          <Box sx={{
            width: '50px',
            height: '50px',
            objectFit: 'contain',
          }} component={"img"} src={"/assets/images/feather_upload-cloud.png"} />
          {!selectedAlbumImage &&
            <>
              <Typography
                variant="p"
                component={"p"}
                sx={{
                  color: theme.palette.primary.LightText,
                  fontWeight: 400,
                  textAlign: 'center',
                  width: '100%',
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "18px",
                    lg: "18px",
                  },
                }}
              >
                Drag & drop files or <span className="boldText">Browse</span>
              </Typography>
              <Typography
                variant="p"
                component={"p"}
                sx={{
                  color: theme.palette.primary.LightText,
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                  },
                }}
              >
                Supported formats: JPEG, PNG, GIF.
              </Typography>
            </>
          }

          <VisuallyHiddenInput
            type="file"
            accept="image/*.jpeg, image/*.png, image/*.gif"
            onChange={handleFileChange}
          />
          {selectedAlbumImage && <Typography
            variant="p"
            component={"p"}
            sx={{
              color: theme.palette.primary.LightText,
              fontWeight: 400,
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "14px",
                lg: "14px",
              },
            }}
          >
            Selected Image: {selectedAlbumImage.name}
          </Typography>}
        </Button>
      </FormControl>
      <FormControl fullWidth
      >
        {[...Array(trackNumber)].map((e, i) => {
          return (
            <TrackNo
              key={i}
              index={i}
              formData={formData}
              setFormDataInTrack={setFormData}
              trackNumber={trackNumber}
              setTrackNumber={setTrackNumber}
              errors={errors}
              setErrors={setErrors}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
            />
          )
        })}

      </FormControl>

      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Album Name *</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="albumName"
          name="albumName"
          placeholder="Enter album name"
          value={formData.albumName}
          onChange={handleFormDataChange}
          onBlur={handleBlur}
        />
        {errors.albumName && <Typography sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.errorText,
          marginTop: '10px',
        }}>
          {' * '}{errors.albumName}
        </Typography>}
      </FormControl>

      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Bio *</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="bio"
          name="bio"
          placeholder="Enter bio"
          value={formData.bio}
          onChange={handleFormDataChange}
          onBlur={handleBlur}
        />
        {errors.bio && <Typography sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.errorText,
          marginTop: '10px',
        }}>
          {' * '}{errors.bio}
        </Typography>}
      </FormControl>

      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Genre</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="genre"
          name="genre"
          placeholder="Enter genre"
          value={formData.genre}
          onChange={handleFormDataChange}
          onBlur={handleBlur}
        />
      </FormControl>



      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Copyright Holder</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="copyrightHolder"
          name="copyrightHolder"
          placeholder="Enter copyright holder"
          value={formData.copyrightHolder}
          onChange={handleFormDataChange}
          onBlur={handleBlur}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{
          marginBottom: '15px',
          color: theme.palette.primary.logoColor,
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }} id="demo-row-radio-buttons-group-label">Music Metadata Version</FormLabel>
        <TextField className="inputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '445px',
            lg: '445px',

          },
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="musicMetadataVersion"
          name="musicMetadataVersion"
          placeholder="Enter version"
          value={formData.musicMetadataVersion}
          onChange={handleFormDataChange}
          onBlur={handleBlur}
        />
      </FormControl>
      <Button
        sx={{
          width: 'auto',
          minWidth: '160px',
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20px",
          color: theme.palette.primary.white,
          padding: '20px 32px 20px 32px',
          marginTop: '50px',
          background: theme.palette.primary.logoColor,
          borderRadius: '10px',
          textTransform: 'capitalize',
          height: '50px',
          boxShadow: '0px 8px 15px 1px #2F2E4159',
          "&:hover": {
            background: theme.palette.primary.logoColor,
            boxShadow: '0px 8px 15px 1px #2F2E4159',
            color: theme.palette.primary.white,
          }
        }}
        variant="contained"
        onClick={handleNextClick}
      >Next</Button>
    </>
  )
}

export { ArtistInformationFormSingle, ArtistInformationFormAlbum };