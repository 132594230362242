import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styles } from '../Onboarding/style'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const PaymentConfirmation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToProMetaData = () => {
        setTimeout(() => {
            navigate("/proMetaData")
        }, 4000);

    }
    useEffect(() => {
        navigateToProMetaData()
    }, [])
    return (
        <div style={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={styles.formPartHeaderContainer}>
                <Box
                    component={"img"}
                    alt='CollectionImage'
                    src={"/assets/images/correct.png"}
                    sx={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                    }}
                />
                <Typography sx={styles.formPartHeaderNew}>
                    Payment Successful
                </Typography>

            </Box>
        </div>
    )
}

export default PaymentConfirmation
